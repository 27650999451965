/** @jsxRuntime classic */
/** @jsx jsx */
import React from "react"
import { Box, Container, Flex, Heading, jsx, Paragraph } from "theme-ui"

import AguilaButton from "components/AguilaButton"
import SectionHeading from "components/SectionHeading"

import { getStrapiCorrectImageSrc } from "helpers"
import BrandBook from "images/icons/brand-book.svg"
import Catalog from "images/icons/catalog.svg"
import Fonts from "images/icons/fonts.svg"
import Guidelines from "images/icons/guidelines.svg"
import Mail from "images/icons/mail.svg"
import Photos from "images/icons/photos.svg"
import Sheets from "images/icons/sheets.svg"

interface MarketingAssetsBlockMapperProps {
  block: any
}

const MarketingAssetsBlockMapper = ({
  block,
}: MarketingAssetsBlockMapperProps) => {
  switch (block.strapi_component) {
    case "marketing-assets-components.logo-download":
      console.log("url", `${getStrapiCorrectImageSrc(block.logo_1.url)}`)
      return (
        <Box as="section" sx={{ position: "relative", pb: 44 }}>
          <Box sx={{ mt: 0, position: "relative", zIndex: 1 }}>
            <SectionHeading>{block.title}</SectionHeading>
            <Container>
              <Box sx={{ maxWidth: "550px" }}>
                <Paragraph>{block.paragraph_1}</Paragraph>
              </Box>
            </Container>
          </Box>
          <div
            sx={{
              width: "100%",
              backgroundColor: "tan",
              py: 5,
            }}
          >
            <Flex
              sx={{
                justifyContent: "center",
                height: "100%",
                alignItems: "center",
                gap: "50px",
                flexWrap: ["wrap", null, null, "nowrap"],
              }}
            >
              <Box>
                <Flex
                  sx={{
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {" "}
                  <img
                    src={getStrapiCorrectImageSrc(block.logo_1.url)}
                    alt=""
                    sx={{
                      width: "100%",
                      flex: 1,
                      maxHeight: "200px",
                      objectFit: "contain",
                      transform: "scale(1.2)",
                    }}
                  />
                  {/* <p sx={{ textAlign: "center", mt: 0 }}>thing</p> */}
                  <AguilaButton
                    sx={{ flex: 1 }}
                    variant="primary"
                    url={getStrapiCorrectImageSrc(
                      block.logo_download_1[0]?.url
                    )}
                    text="download"
                  ></AguilaButton>
                </Flex>
              </Box>
              <Box>
                <Flex
                  sx={{
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {" "}
                  <img
                    src={getStrapiCorrectImageSrc(block.logo_2.url)}
                    alt=""
                    sx={{
                      width: "100%",
                      flex: 1,
                      maxHeight: "200px",
                      objectFit: "contain",
                    }}
                  />
                  {/* <p sx={{ textAlign: "center", mt: 0 }}>thing</p> */}
                  <AguilaButton
                    sx={{ flex: 1 }}
                    variant="primary"
                    url={getStrapiCorrectImageSrc(
                      block.logo_download_2[0]?.url
                    )}
                    text="download"
                  ></AguilaButton>
                </Flex>
              </Box>
            </Flex>
          </div>
        </Box>
      )
    case "marketing-assets-components.brand-guidelines":
      return (
        <Box as="section" sx={{ position: "relative", pb: 44 }}>
          <Box sx={{ mt: 0, position: "relative", zIndex: 1 }}>
            <SectionHeading>{block.title}</SectionHeading>
            <Container>
              <Flex sx={{ flexWrap: ["wrap", null, null, "nowrap"] }}>
                <Box sx={{ flex: ["none", null, null, 1], width: "100%" }}>
                  <Paragraph>{block.paragraph_1}</Paragraph>
                  <Paragraph>{block.paragraph_2}</Paragraph>
                </Box>
                <Box sx={{ flex: ["none", null, null, 1], width: "100%" }}>
                  <Box
                    sx={{
                      backgroundColor: "tan",
                      ml: [0, null, null, 20],
                      mt: 10,
                      py: 5,
                      boxShadow: "-20px -20px 0px 0px",
                    }}
                  >
                    <Flex
                      sx={{
                        justifyContent: ["space-around", null, null, "center"],
                        gap: 4,
                      }}
                    >
                      <Box>
                        <Flex
                          sx={{
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: 2,
                          }}
                        >
                          <Heading
                            as="h3"
                            variant="title"
                            sx={{
                              textAlign: "center",
                              mt: 3,
                              zIndex: 1,
                            }}
                          >
                            Guidelines
                          </Heading>
                          <Guidelines
                            sx={{ height: "120px", width: "120px" }}
                          />
                          <AguilaButton
                            variant="primary"
                            url={getStrapiCorrectImageSrc(
                              block.download_1[0]?.url
                            )}
                            text="download"
                          ></AguilaButton>
                        </Flex>
                      </Box>
                      <Box>
                        <Flex
                          sx={{
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: 2,
                          }}
                        >
                          <Heading
                            as="h3"
                            variant="title"
                            sx={{
                              textAlign: "center",
                              mt: 3,
                              zIndex: 1,
                            }}
                          >
                            Fonts
                          </Heading>
                          <Fonts sx={{ height: "120px", width: "120px" }} />
                          <AguilaButton
                            variant="primary"
                            url={getStrapiCorrectImageSrc(
                              block.download_2[0]?.url
                            )}
                            text="download"
                          ></AguilaButton>
                        </Flex>
                      </Box>
                    </Flex>
                  </Box>
                </Box>
              </Flex>
            </Container>
          </Box>
        </Box>
      )
    case "marketing-assets-components.catalog-and-sale-sheets":
      return (
        <Box as="section" sx={{ position: "relative", pb: 44 }}>
          <Box sx={{ mt: 0, position: "relative", zIndex: 1 }}>
            <SectionHeading>{block.title}</SectionHeading>
            <Container>
              <Paragraph>{block.paragraph_1}</Paragraph>
              <Paragraph>{block.paragraph_2}</Paragraph>
              <Box
                sx={{
                  backgroundColor: "tan",
                  padding: 4,
                  maxWidth: "1220px",
                  mx: [0, null, null, "50px"],
                }}
              >
                <Flex sx={{ justifyContent: "space-around" }}>
                  <Box>
                    <Flex
                      sx={{
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: 2,
                      }}
                    >
                      {" "}
                      <Heading
                        as="h3"
                        variant="title"
                        sx={{
                          textAlign: "center",
                          mt: 0,
                          zIndex: 1,
                        }}
                      >
                        Catalog
                      </Heading>
                      <Catalog sx={{ height: "120px", width: "120px" }} />
                      <AguilaButton
                        variant="primary"
                        url={getStrapiCorrectImageSrc(block.download_1[0]?.url)}
                        text="download"
                      ></AguilaButton>
                    </Flex>
                  </Box>
                  <Box>
                    <Flex
                      sx={{
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: 2,
                      }}
                    >
                      <Heading
                        as="h3"
                        variant="title"
                        sx={{
                          textAlign: "center",
                          mt: 0,
                          zIndex: 1,
                        }}
                      >
                        Sale Sheets
                      </Heading>
                      <Sheets sx={{ height: "120px", width: "120px" }} />
                      <AguilaButton
                        variant="primary"
                        url={getStrapiCorrectImageSrc(block.sale_sheet_url)}
                        text="download"
                      ></AguilaButton>
                    </Flex>
                  </Box>
                </Flex>
              </Box>
            </Container>
          </Box>
        </Box>
      )
    case "marketing-assets-components.packaging-and-bullet-images":
      return (
        <Box as="section" sx={{ position: "relative", pb: 44 }}>
          <Box sx={{ mt: 0, position: "relative", zIndex: 1 }}>
            <SectionHeading>{block.title}</SectionHeading>
            <Container>
              <Paragraph>{block.paragraph_1}</Paragraph>
              <Box sx={{ mt: 10 }}>
                <Flex
                  sx={{ gap: 10, flexWrap: ["wrap", null, null, "nowrap"] }}
                >
                  <Box
                    sx={{
                      flex: ["none", null, null, 1],
                      backgroundColor: "tan",
                      boxShadow: "-20px -20px 0px 0px",
                      py: 5,
                      width: ["100%", null, null, "auto"],
                      mx: ["auto", null, null, 0],
                    }}
                  >
                    {" "}
                    <Box sx={{}}>
                      <Flex
                        sx={{
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: 2,
                        }}
                      >
                        {" "}
                        <Heading
                          as="h3"
                          variant="title"
                          sx={{
                            mt: 0,
                            zIndex: 1,
                          }}
                        >
                          Rimfire
                        </Heading>
                        <img
                          src={getStrapiCorrectImageSrc(
                            block.rimfire_package.url
                          )}
                          alt=""
                          sx={{
                            width: "100%",
                            flex: 1,
                            maxHeight: "200px",
                            objectFit: "contain",
                            ml: 10,
                          }}
                        />
                        <AguilaButton
                          variant="primary"
                          url={getStrapiCorrectImageSrc(
                            block.rimfire_package_download
                          )}
                          text="download"
                        ></AguilaButton>
                      </Flex>
                    </Box>
                    <Box sx={{ textAlign: "center" }}>
                      <Flex
                        sx={{
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: 2,
                        }}
                      >
                        {" "}
                        <img
                          src={getStrapiCorrectImageSrc(
                            block.rimfire_bullet.url
                          )}
                          alt=""
                          sx={{
                            width: "100%",
                            flex: 1,
                            maxHeight: "200px",
                            objectFit: "contain",
                          }}
                        />
                        <AguilaButton
                          variant="primary"
                          url={getStrapiCorrectImageSrc(
                            block.rimfire_bullet_download
                          )}
                          text="download"
                        ></AguilaButton>
                      </Flex>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      flex: ["none", null, null, 1],
                      backgroundColor: "tan",
                      boxShadow: "-20px -20px 0px 0px",
                      py: 5,
                      width: ["100%", null, null, "auto"],
                      mx: ["auto", null, null, 0],
                    }}
                  >
                    {" "}
                    <Box sx={{ textAlign: "center" }}>
                      <Flex
                        sx={{
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: 2,
                        }}
                      >
                        <Heading
                          as="h3"
                          variant="title"
                          sx={{
                            mt: 0,
                            zIndex: 1,
                          }}
                        >
                          Centerfire
                        </Heading>
                        <img
                          src={getStrapiCorrectImageSrc(
                            block.centerfire_package.url
                          )}
                          alt=""
                          sx={{
                            width: "100%",
                            flex: 1,
                            maxHeight: "200px",
                            objectFit: "contain",
                          }}
                        />
                        <AguilaButton
                          variant="primary"
                          url={getStrapiCorrectImageSrc(
                            block.centerfire_packaging_url
                          )}
                          text="download"
                        ></AguilaButton>
                      </Flex>
                    </Box>
                    <Box sx={{ textAlign: "center" }}>
                      <Flex
                        sx={{
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: 2,
                        }}
                      >
                        <img
                          src={getStrapiCorrectImageSrc(
                            block.centerfire_bullet.url
                          )}
                          alt=""
                          sx={{
                            width: "100%",
                            flex: 1,
                            maxHeight: "200px",
                            objectFit: "contain",
                          }}
                        />
                        <AguilaButton
                          variant="primary"
                          url={getStrapiCorrectImageSrc(
                            block.centerfire_bullet_download
                          )}
                          text="download"
                        ></AguilaButton>
                      </Flex>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      flex: ["none", null, null, 1],
                      py: 5,
                      backgroundColor: "tan",
                      boxShadow: "-20px -20px 0px 0px",
                      width: ["100%", null, null, "auto"],
                      mx: ["auto", null, null, 0],
                    }}
                  >
                    {" "}
                    <Box sx={{ textAlign: "center" }}>
                      <Flex
                        sx={{
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: 2,
                        }}
                      >
                        <Heading
                          as="h3"
                          variant="title"
                          sx={{
                            mt: 0,
                            zIndex: 1,
                          }}
                        >
                          Shotshell
                        </Heading>
                        <img
                          src={getStrapiCorrectImageSrc(
                            block.shotshell_package.url
                          )}
                          alt=""
                          sx={{
                            width: "100%",
                            flex: 1,
                            maxHeight: "200px",
                            objectFit: "contain",
                          }}
                        />
                        <AguilaButton
                          variant="primary"
                          url={getStrapiCorrectImageSrc(
                            block.shell_package_download
                          )}
                          text="download"
                        ></AguilaButton>
                      </Flex>
                    </Box>
                    <Box sx={{ textAlign: "center" }}>
                      <Flex
                        sx={{
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: 2,
                        }}
                      >
                        {" "}
                        <img
                          src={getStrapiCorrectImageSrc(
                            block.shotshell_bullet.url
                          )}
                          alt=""
                          sx={{
                            width: "100%",
                            flex: 1,
                            maxHeight: "200px",
                            objectFit: "contain",
                          }}
                        />
                        <AguilaButton
                          variant="primary"
                          url={getStrapiCorrectImageSrc(
                            block.shell_bullet_download
                          )}
                          text="download"
                        ></AguilaButton>
                      </Flex>
                    </Box>
                  </Box>
                </Flex>
              </Box>
            </Container>
          </Box>
        </Box>
      )
    case "marketing-assets-components.other-assets":
      return (
        <Box as="section" sx={{ position: "relative", pb: 44 }}>
          <Box sx={{ mt: 0, position: "relative", zIndex: 1 }}>
            <SectionHeading>{block.title}</SectionHeading>
          </Box>
          <div
            sx={{
              width: "100%",
              backgroundColor: "tan",
              py: 5,
            }}
          >
            <Flex
              sx={{
                height: "100%",
                alignItems: "center",
                gap: "100px",
                maxWidth: "1220px",
                mx: "auto",
                px: "10px",
                justifyContent: "space-around",
                flexWrap: ["wrap", null, null, "nowrap"],
              }}
            >
              <Box>
                <Flex
                  sx={{
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 2,
                  }}
                >
                  <Heading
                    as="h3"
                    variant="title"
                    sx={{
                      textAlign: "center",
                      mt: 0,
                      zIndex: 1,
                    }}
                  >
                    Lifestyle Images
                  </Heading>
                  <Photos sx={{ height: "120px", width: "120px" }} />
                  <AguilaButton
                    variant="primary"
                    url={getStrapiCorrectImageSrc(block.lifestyle_images_url)}
                    text="download"
                  ></AguilaButton>
                </Flex>
              </Box>
              <Box>
                <Flex
                  sx={{
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 2,
                  }}
                >
                  <Heading
                    as="h3"
                    variant="title"
                    sx={{
                      textAlign: "center",
                      mt: 0,
                      zIndex: 1,
                    }}
                  >
                    Email Template
                  </Heading>
                  <Mail sx={{ height: "120px", width: "120px" }} />
                  <AguilaButton
                    variant="primary"
                    url={getStrapiCorrectImageSrc(block.email_template[0]?.url)}
                    text="download"
                  ></AguilaButton>
                </Flex>
              </Box>
              <Box>
                <Flex
                  sx={{
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 2,
                  }}
                >
                  <Heading
                    as="h3"
                    variant="title"
                    sx={{
                      textAlign: "center",
                      mt: 0,
                      zIndex: 1,
                    }}
                  >
                    Brand Book
                  </Heading>
                  <BrandBook sx={{ height: "120px", width: "120px" }} />
                  <AguilaButton
                    variant="primary"
                    url={getStrapiCorrectImageSrc(block.brand_book[0]?.url)}
                    text="download"
                  ></AguilaButton>
                </Flex>
              </Box>
            </Flex>
          </div>
        </Box>
      )
    default:
      return <></>
  }
}

export default MarketingAssetsBlockMapper
